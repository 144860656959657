// Bootstrap JS.
// Comment out the library you are not using.
// -----------------------------------------------------------------------------
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
// import "bootstrap/js/dist/carousel";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "bootstrap/js/dist/offcanvas";
// import "bootstrap/js/dist/modal";
// import "bootstrap/js/dist/popover";
// import "bootstrap/js/dist/scrollspy";
import "bootstrap/js/dist/tab";
export { default as Modal } from "bootstrap/js/dist/modal";
// export { default as Toast } from "bootstrap/js/dist/toast";
export { default as Tooltip } from "bootstrap/js/dist/tooltip";
